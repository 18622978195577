/* eslint-disable max-statements */
// modules
import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { path } from 'ramda';
import cx from 'classnames';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { fromBase64 } from '@vl/js-lib/browser/string';

// aliased
import { useRelayConnection } from 'lib/hooks/useRelayConnection';
import ActivityList from 'components/ActivityList';
import Error from 'components/Error';
import { useInterval } from 'lib/hooks/useInterval';

// local
import styles from './UserView.module.scss';

const USER_ACTIVITIES = gql`
query UserActivities($id: ID!, $input: UserActivitiesInput) {
  node(id: $id) {
    ...on User {
      id
      activities(input: $input) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor 
          node {
            id
            type
            duration
            startDate
            endDate
            app { id name }
            collection { id name }
            user { id, nuId }
          }
        }
      }
    }
  }
}
`;


const UserView = () => {
  const { id: userId } = useParams();
  const [enablePolling, setEnablePolling] = useState(false);
  const [sorts, setSorts] = useState([{ field: 'startDate', sort: 'desc' }]);

  const {
    data: activities,
    loading,
    error,
    page, nextPage, prevPage,
    setPage, totalPages,
    refetch,
  } = useRelayConnection(USER_ACTIVITIES, {
    getConnection: path(['node', 'activities']),
    returnFormat: 'nodes',
    variables: {
      id: userId,
      input: {
        sortOrder: R.toUpper(path([0, 'sort'], sorts)),
      },
    },
  });

  const handlePollingChange = useCallback(event => {
    const enablePolling = event.target.checked;
    // leading-edge refetch when enabled
    if (enablePolling) refetch();
    setEnablePolling(enablePolling);
  }, []);

  useInterval(() => refetch(), enablePolling ? 30000 : null);

  const palette = R.path(['palette', 'type'], useTheme());

  if (error) return <Error error={ error } />;

  // fixme: better to get this from gql
  const nuId = R.applyTo(userId, R.pipe(
    fromBase64,
    R.split(':'),
    R.last,
  ));

  return (
    <Container className={ cx(styles.profileView, styles[palette]) }>
      <Typography variant="h3" align={ 'center' } style={{ lineHeight: 'initial', marginTop: '40px' }}>
        User
      </Typography>
      <Typography variant="h4" align={ 'center' }>
        <b>nuId:</b> { nuId }
      </Typography>
      <Typography variant="h4" align={ 'center' } style={{ marginBottom: '20px' }}>
        <b>id:</b> { userId }
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <FormControlLabel
          control={
            <Switch
              checked={ enablePolling }
              onChange={ handlePollingChange }
              name="checked"
              color="secondary" />
          }
          label="Live Data"
        />
      </div>
      <ActivityList
        activities={ activities }
        columns={{ user: false }}
        page={ page }
        nextPage={ nextPage }
        prevPage={ prevPage }
        setPage={ setPage }
        totalPages={ totalPages }
        loading={ loading }
        sort={ sorts }
        onSortModelChange={ setSorts }
      />
    </Container>
  );
};

export default UserView;
