import * as R from 'ramda';
import { capitalize } from '@vl/js-lib/browser/string';

// Formats machine-readable slugs for humans
// String -> String
export const unslugify = R.pipe(
  R.toLower,
  R.split('_'),
  R.map(R.pipe(R.toLower, capitalize)),
  R.join(' '),
);
