// modules
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';

// local
import styles from './Link.module.scss';

const isLocalAddress = (to) => {
  const startsWithSlash = /^\//.test(to);
  const startsWithProto = /^https?/.test(to);
  const isMailto = /^mailto/.test(to);
  if (isMailto) return false;
  return startsWithSlash || !startsWithProto;
};

// eslint-disable-next-line complexity
const Link = ({
  children,
  className,
  disabled,
  download,
  hidden,
  onClick,
  openNew,
  state,
  style,
  title,
  to,
}) => {

  if (hidden) return null;
  
  const classes = cx(className, styles.link, {
    [styles.disabled]: disabled,
    [styles.text]: (typeof children === 'string'),
    [styles.notALink]: !(to || onClick),
  });

  // unclickable link
  if (!to || disabled) {
    return (
      <div
        className={ classes }
        title={ title }
        onClick={ (disabled ? null : onClick) }
        style={ style }
        children={ children } />
    );
  }

  // local link
  if (isLocalAddress(to)) {
    return (
      <MuiLink
        className={ cx(styles.internalLink, classes) }
        download={ download }
        title={ title }
        style={ style }
        // openNew defaults to false for internal links
        target={ (openNew === undefined) ? null : (openNew ? '_blank' : null) }
        onClick={ onClick }
        to={ to }
        state={ state }
        children={ children || '' }
        component={ RouterLink } />
    );
  }

  // external link
  return (
    <MuiLink
      className={ cx(styles.externalLink, classes) }
      download={ download }
      href={ to }
      children={ children || '' }
      onClick={ onClick }
      style={ style }
      // openNew defaults to true for external links
      target={ (openNew === undefined) ? '_blank' : (openNew ? '_blank' : null) }
      title={ title }
      // todo: need a prop to control noreferrer
      rel={ (openNew ? 'noopener noreferrer' : null) }  />
  );
};

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  download: PropTypes.string,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  openNew: PropTypes.bool,
  state: PropTypes.shape(),
  style: PropTypes.shape(),
  title: PropTypes.string,
  to: PropTypes.string,
};

Link.defaultProps = {
  disabled: false,
};

export default Link;
