// modules
import React, { useReducer, useCallback } from 'react';
import { Container } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import * as R from 'ramda';

// aliased
import Title from 'components/Title';

// local
import styles from './HomeView.module.scss';
import CollectionSelector from 'components/AppCollectionSelector';
import CollectionActivities from 'components/CollectionActivities';
import AppActivities from 'components/AppActivities';
import ActivityList from 'components/ActivityList';

function reducer(state, action) {
  const { app, collection } = action;
  if ('app' in action) return { ...state, app, collection: null };
  if ('collection' in action) return { ...state, collection };
  return state;
}

const HomeView = () => {
  const theme = R.path(['palette', 'type'], useTheme());
  const [{ app, collection }, dispatch] = useReducer(reducer, {
    app: null,
    collection: null,
  });

  const handleSetApp = useCallback(app => dispatch({ app }));
  const handleSetCollection = useCallback(collection => dispatch({ collection }));

  return (
    <Container className={ cx(styles.container, styles[theme]) }>
      <Title title={ 'Home' } />
      <CollectionSelector
        style={{ marginBottom: '20px' }}
        appId={ app?.id }
        collectionId={ collection?.id }
        onAppSelected={ handleSetApp }
        onCollectionSelected={ handleSetCollection }
      />
      {
        !app && (
          <ActivityList
            noRowsOverlay={ 'Select an App' }
            activities={ [] } />
        )
      }
      { app && !collection && <AppActivities appId={ app?.id } /> }
      { app && collection && <CollectionActivities collectionId={ collection?.id } /> }
    </Container>
  );
};

export default HomeView;
