// modules
import { useEffect, useState } from 'react';

// (String, String) -> (String, String -> Undefined)
export const useLocalStorage = (key, fallback) => {
  const initState = localStorage.getItem(key) ?? fallback;
  const [value, setValue] = useState(initState);

  useEffect(_ => localStorage.setItem(key, value), [value, key]);

  return [value, setValue];
};
