/* eslint-disable max-statements */
import React, { useState } from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import { applyTo, pipe } from 'ramda';
import { useTheme } from '@material-ui/core/styles';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import Error from 'components/Error';


// local
import styles from './AppActivities.module.scss';
import { gql } from '@apollo/client';
import { useRelayConnection } from 'lib/hooks/useRelayConnection';
import ActivityList from 'components/ActivityList';

const APP_ACTIVITIES = gql`
query AppActivities($id: ID!, $input: AppActivitiesInput) {
	node(id: $id) {
		... on App {
			id
			name
			activities(input: $input) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
				edges {
					node {
            id
            type
            duration
            app { id, name }
            collection { id, name }
            startDate
            endDate
            user { id nuId }
					}
				}
			}
		}
	}
}
`;

export default applyTo(({
  appId,
}) => {
  const [sorts, setSorts] = useState([{ field: 'startDate', sort: 'desc' }]);
  const theme = R.path(['palette', 'type'], useTheme());

  const {
    data: activities,
    loading,
    error,
    page,
    nextPage,
    prevPage,
    setPage,
    totalPages,
  } = useRelayConnection(APP_ACTIVITIES, {
    getConnection: R.path(['node', 'activities']),
    returnFormat: 'nodes',
    variables: {
      id: appId,
    },
  });

  if (error) return <Error error={ error } />;

  return (
    <ActivityList
      className={ cx(styles.container, styles[theme]) }
      activities={ activities }
      nextPage={ nextPage }
      prevPage={ prevPage }
      setPage={ setPage }
      page={ page }
      totalPages={ totalPages }
      loading={ loading }
      sort={ sorts }
      columns={{
        app: false,
      }}
      onSortModelChange={ setSorts }
    />
  );
}, pipe(
  propTypes({

  }),
  defaultProps({
  }),
));
