// modules
import { useOktaAuth } from '@okta/okta-react';

// _ -> _ -> Promise
export const useOktaLogout = () => {
  const { oktaAuth } = useOktaAuth();

  return async _ => {
    // log user out of console (not okta)
    oktaAuth.tokenManager.clear();
    // log user out of okta (and console)
    await oktaAuth.signOut({ postLogoutRedirectUri: '/login' });
  };
};
