// modules
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns'; // side-effect
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';

// aliased
import { unslugify } from 'lib/string';
import { stringColor } from 'lib/color';

// local
import styles from './MetricChart.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

// [Metric] -> (Date, Date)
const metricTimeRange = R.juxt([
  R.pipe(R.head, R.prop('startDate')),
  R.pipe(R.last, R.prop('endDate')),
]);

// Metric -> String
const metricUnit = R.pipe(
  R.prop('type'),
  R.cond([
    [R.equals('DISTANCE'), _ => 'KM'],
    [R.equals('PACE'), _ => 'MKM'],
    [R.equals('HEART_RATE'), _ => 'BPM'],
    [R.equals('ELEVATION'), _ => 'Meters'],
    [R.equals('HORIZONTAL_ACCURACY'), _ => 'Meters'],
    [R.equals('VERTICAL_ACCURACY'), _ => 'Meters'],
    [R.equals('LATITUDE'), _ => '°'],
    [R.equals('LONGITUDE'), _ => '°'],
    [R.equals('SPEED'), _ => 'KMH'],
  ]),
);

const MetricChart = ({ metrics: allMetrics, formatDate }) => {

  const windowInSec = Infinity;

  const [
    // eslint-disable-next-line no-unused-vars
    startDate = new Date(),
    metricRangeEnd = new Date(),
  ] = metricTimeRange(allMetrics);

  const metrics = R.applyTo(allMetrics, R.pipe(
    // windowing
    R.takeLastWhile(({ endDate }) => (
      ~~((metricRangeEnd - endDate) / 1000) <= windowInSec
    )),
  ));
  
  const metricTypes = metrics.reduce((set, { type }) => set.add(type), new Set());

  const options = {
    responsive: true,
    // maintainAspectRatio: false,
    animation: false,
    // animation: {
    //   duration: 1000,
    //   easing: 'linear',
    // },
    scales: {
      x: {
        display: !!metrics.length,
        type: 'time',
        time: {
          round: 'second',
        },
        ticks: {
          callback: R.pipe(
            date => new Date(date),
            formatDate,
          ),

          ...R.applyTo(metrics, R.pipe(
            metricTimeRange,
            ([min, max]) => ({ min, max }),
          )),
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          title: R.pipe(
            R.path([0, 'parsed', 'x']),
            date => new Date(date),
            formatDate,
          ),
          label: R.pipe(
            R.juxt([
              R.pipe(
                R.path(['dataset', 'metricType']),
                type => metricUnit({ type }) || '',
              ),
              R.pathOr('', ['dataset', 'label']),
              R.path(['formattedValue']),
            ]),
            ([unit, label, value]) => `${ label }: ${ value } ${ unit }`,
          ),
        },
      },
    },
  };
  
  // must spread to get map method on a Set
  const datasets = [...metricTypes].map(type => ({
    label: unslugify(type),
    metricType: type,
    data: R.applyTo(metrics, R.pipe(
      R.filter(({ type: metricType }) => (metricType === type)),
      // eslint-disable-next-line id-length
      R.map(({ value: y, endDate: x }) => ({ x, y })),
    )),
    cubicInterpolationMode: 'monotone', // round the lines
    pointRadius: 1,
    borderWidth: 1,
    borderColor: stringColor(type),
    backgroundColor: stringColor(type),
  }));

  return (
    <Line
      className={ styles.metricChart }
      options={ options }
      data={{ datasets }} />
  );
};

const MetricType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
});

MetricChart.propTypes = {
  formatDate: PropTypes.func,
  metrics: PropTypes.arrayOf(MetricType),
};

MetricChart.defaultProps = {
  formatDate: date => date.toISOString(),
  metrics: [],
};

export default MetricChart;
