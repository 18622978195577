/* eslint-disable max-statements */
import { useState, useEffect, useCallback } from 'react';
import * as R from 'ramda';
import { useLazyQuery } from '@apollo/client';
import { throwError } from '@vl/js-lib/browser/error';

// aliased
import { connectionNodes } from 'lib/graphql/relay';

// what data should be returned from the collection?
const RETURN_FORMATS = {
  // entire data object
  ALL: 'all',
  // only the connection within the data
  CONNECTION: 'connection',
  // only the edges within the connection
  EDGES: 'edges',
  // only the nodes within the edges
  NODES: 'nodes',
};

// finds all connections
const findConnections = R.pipe(
  R.toPairs,
  R.chain(([key, val]) => {
    if (R.prop('pageInfo', val)) return [[[key], val]];
    if (R.prop('edges', val)) return [[[key], val]];
    return [];
  }),
);

// finds the first connection in a graphql result
const findFirstConnection = R.pipe(
  R.ifElse(R.isNil, _ => null, R.pipe(
    findConnections,
    R.tap(R.when(con => con.length > 1, connections => {
      throw Error(
        `[useRelay] Found multiple relay connections [${ connections.map(R.head).join(', ') }] Please set config.getConnection`
      );
    })),
    // first connection pair
    R.head,
    // connection value
    R.nth(1),
  )),
);

// RelayConnection -> String
const getEndCursor = R.pipe(
  R.juxt([
    R.path(['pageInfo', 'endCursor']),
    R.pipe(
      R.pathOr([], ['edges']),
      R.last,
      R.prop('cursor'),
    ),
  ]),
  ([endCursor, lastCursor]) => (endCursor || lastCursor),
);

// Object -> RelayConnection
const defaultGetConnection = R.pipe(
  R.unless(R.isNil, R.pipe(
    findFirstConnection,
    R.when(R.isNil, _ => throwError(Error('[useRelay] No relay connection found. Please set config.getConnection')))
  )),
);

// useEffect that ignores the initial call
const useChange = (fn, deps) => {
  const [called, setCalled] = useState(false);

  useEffect((...args) => {
    // eslint-disable-next-line no-unused-expressions
    called
      ? fn(...args)
      : setCalled(true);
  }, deps);
};

// fixme: this needs to handle changes to query / page size etc?
// GraphQLQuery -> Object -> Object
export const useLazyRelayConnection = (query, {
  name,
  // selects the connection type from the resulting query data
  getConnection = defaultGetConnection,
  // when true: only returns the results of getConnection
  returnFormat = RETURN_FORMATS.ALL,
  variables: initialVariables = {},
  // lens pointing to input args
  inputLens = R.lensProp('input'),
  // called when query completes
  // onCompleted = data => {},
  // eslint-disable-next-line handle-callback-err
  onError = err => {},
  // keyArgs = _ => '',

  // https://www.apollographql.com/docs/react/data/queries/#setting-a-fetch-policy
  fetchPolicy,
  // https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
  errorPolicy,
}) => {
  const queryStr = R.path(['loc', 'source', 'body'], query);
  const afterLens = R.compose(inputLens, R.lensProp('after'));

  // a change in key results in a full re-initialization of the hook
  const key = JSON.stringify(initialVariables);
  const [cursors, setCursors] = useState([null]); // each page's start cursor
  const [page, setPage] = useState(1); // current index (1-based)
  const [globalError, setGlobalError] = useState(null);

  const handleError = useCallback(({ graphqlErrors, networkError }) => {
    setGlobalError(networkError || graphqlErrors[0]);
  }, [setGlobalError]);
  
  const handleComplete = (data, { page, cursors }) => {
    const connection = getConnection(data);
    if (!connection) {
      return setGlobalError(Error(`[useRelay] "${ name }" - Missing connection in query: ${ queryStr }`));
    }
    const pageInfo = R.prop('pageInfo', connection);
    if (!pageInfo) return setGlobalError(Error(`[useRelay] "${ name }" - Missing pageInfo in query: ${ queryStr }`));
    if (!R.has('hasNextPage', pageInfo)) {
      return setGlobalError(Error(`[useRelay] "${ name }" - Missing pageInfo.hasNextPage in query: ${ queryStr }`));
    }

    const hasNextPage = R.prop('hasNextPage', pageInfo);
    const endCursor = getEndCursor(connection);

    if (!hasNextPage) return;
    if (hasNextPage && !endCursor) {
      return setGlobalError(Error(`[useRelay] "${ name }" - Missing pageInfo.endCursor in query: ${ queryStr }`));
    }
    // this is a new page, add its end cursor to the list
    if (page >= cursors.length) {
      setCursors(R.append(endCursor, cursors));
    }
  };

  const [
    runQuery,
    { data, loading, error, fetchMore, refetch },
  ] = useLazyQuery(query, {
    onError: handleError,
    notifyOnNetworkStatusChange: true,
    errorPolicy,
    fetchPolicy,
  });

  // triggers the query lazily
  const handleRunQuery = useCallback(_ => {
    runQuery({ variables: initialVariables }).then(res => {
      handleComplete(res.data, { page: 1, cursors: [null] });
    });
  }, [runQuery, initialVariables]);

  // every time the query / variables fundamentally change
  // re-initialize the hook
  useChange(() => {
    setPage(1);
    setCursors([null]);
    handleRunQuery();
  }, [key]);

  const handleRefetch = useCallback(_ => {
    refetch(R.set(afterLens, cursors[page - 1], initialVariables));
  }, [refetch, initialVariables, cursors]);

  const handleChange = useCallback(newPage => {
    if (newPage === page) return;
    if (newPage < 1 || newPage > cursors.length) {
      return setGlobalError(Error(`[useRelay] "${ name }" - Page ${ newPage } is out of bounds`));
    }
    
    const cursor = R.prop(newPage - 1, cursors);
    const vars = R.set(afterLens, cursor, initialVariables);

    setPage(newPage);
    fetchMore({ variables: vars }).then(res => {
      handleComplete(res.data, { page: newPage, cursors });
    });
  }, [page, cursors, initialVariables, fetchMore, handleComplete]);

  const handleNext = useCallback(() => handleChange(page + 1), [page, handleChange]);
  const handlePrev = useCallback(() => handleChange(page - 1), [page, handleChange]);

  const connection = getConnection(data);
  const pageInfo = R.prop('pageInfo', connection);

  if (data && !pageInfo && !globalError) {
    const msg = `[useRelay] "${ name }" - pageInfo not found in query "${ queryStr }". Check config.getConnection`;
    setGlobalError(Error(msg));
  }

  return [handleRunQuery, {
    data: R.applyTo(data, R.cond([
      [_ => (returnFormat === RETURN_FORMATS.ALL), R.identity],
      [_ => (returnFormat === RETURN_FORMATS.CONNECTION), getConnection],
      [_ => (returnFormat === RETURN_FORMATS.EDGES), R.pipe(getConnection, R.propOr([], 'edges'))],
      [_ => (returnFormat === RETURN_FORMATS.NODES), R.pipe(getConnection, connectionNodes)],
      [R.T, _ => throwError(Error(`[useRelay] "${ name }" - Invalid return format "${ returnFormat }"`))],
    ])),
    loading,
    error: (error || globalError),
    nextPage: R.prop('hasNextPage', pageInfo)
      ? handleNext
      : null,
    prevPage: R.prop('hasPreviousPage', pageInfo)
      ? handlePrev
      : null,
    page,
    setPage: handleChange,
    totalPages: cursors.length,
    refetch: handleRefetch,
  }];
};
