/* eslint-disable react/no-multi-comp */
// modules
import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';

// local
import styles from './NestedData.module.scss';

const NestedData = ({ data, style, className, ...props }) => {
  const classNames = cx(styles.nestedData, className);
  if (Array.isArray(data)) {
    return (
      <span style={{ padding: '0 1px' }}>
        [
        {
          R.addIndex(R.chain)((dat, i) => [
            (<NestedData key={ i } data={ dat } style={{ padding: '0 1px' }} />),
            (i >= data.length - 1) ? null : ', ',
          ], data)
        }
        ]
      </span>
    );
  }
  
  if (typeof data !== 'object' || isValidElement(data)) {
    return (
      <span { ...props } style={ style }>
        { data }
      </span>
    );
  }

  return (
    <table { ...props } className={ classNames } style={{ borderCollapse: 'collapse', ...style }}>
      <tbody>
        {
          R.applyTo(data, R.pipe(
            R.toPairs,
            R.map(([key, value]) => (
              <tr key={ key }>
                <td style={{ padding: '5px 10px 5px 20px', fontWeight: 'bold', background: 'rgba(0, 0, 0, 0.1)', textAlign: 'right' }}>
                  { key }
                </td>
                <td style={{ padding: '5px 0 5px 10px' }}>
                  {
                    (typeof value === 'object')
                      ? <NestedData data={ value } />
                      : value
                  }
                </td>
              </tr>
            )),
          ))
        }
      </tbody>
    </table>
  );
};

NestedData.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  style: PropTypes.shape({}),
};

export default NestedData;
