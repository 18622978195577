// modules
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';

// aliased
import FlexCenter from 'components/FlexCenter';
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './NotFound.module.scss';

export default R.applyTo(({ title }) => {
  const navigate = useNavigate();
  
  return (
    <FlexCenter>
      { title && <Typography variant='h1'>{ title }</Typography> }
      <Typography variant='h1'>Not Found</Typography>
      <div className={ styles.buttons }>
        { /* eslint-disable-next-line react/jsx-no-bind */ }
        <Button className={ styles.button } onClick={ _ => navigate(-1) }>
          { 'Back' }
        </Button>
        { /* eslint-disable-next-line react/jsx-no-bind */ }
        <Button className={ styles.button } onClick={ _ => window.location.reload() }>
          { 'Retry' }
        </Button>
      </div>
    </FlexCenter>
  );
}, R.pipe(
  propTypes({
    title: PropTypes.string,
  }),
  defaultProps({

  }),
  memo,
));
