// modules
import * as R from 'ramda';
import React, { memo, useCallback, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate, Outlet } from 'react-router-dom';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { ThemeProvider } from '@material-ui/styles';

// aliased
import { useGATracking } from 'lib/hooks/useGATracking';
import { useOktaUser } from 'lib/hooks/useOktaUser';
import { useOktaLogout } from 'lib/hooks/useOktaLogout';
import { useLocalStorage } from 'lib/hooks/useLocalStorage';
import { propTypes, defaultProps } from 'lib/react';
import DARK_THEME from 'lib/themes/dark';
import LIGHT_THEME from 'lib/themes/light';

// components
import Error from 'components/Error';
import Login from 'components/Login';
import NotFound from 'components/NotFound';
import Layout from 'components/Layout';
import HomeView from 'views/HomeView';
import VisualizeView from 'views/VisualizeView';
import ProfileView from 'views/ProfileView';
import UserView from 'views/UserView';
import ActivityView from 'views/ActivityView';

import config from 'conf';



// eslint-disable-next-line react/no-multi-comp
const OktaAuthenticatedRoute = () => {
  const { authState } = useOktaAuth();
  const location = useLocation();

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return  R.propOr(false, 'isAuthenticated', authState)
    ? <Outlet />
    : <Navigate to={ '/login' } state={{ redirect: location.pathname }} />;
};

export default R.applyTo(() => {
  useGATracking(config.GOOGLE_ANALYTICS_ID);
  const [theme, setTheme] = useLocalStorage('ars_console_theme', 'light');
  const location = useLocation();
  const user = useOktaUser();
  const logout = useOktaLogout();
  
  // scroll to top of page on every transition
  useEffect(() => window.scrollTo(0, 0), [location]);

  const handleLogout = useCallback(_ => {
    logout()
      .catch(err => console.error(`Error Logging Out: ${ err }`));
  }, [logout]);

  const layout = (
    <Layout
      user={ user }
      supportEmail={ config.SUPPORT_EMAIL }
      onLogout={ handleLogout }
      onToggleTheme={ setTheme } />
  );

  return (
    <ThemeProvider theme={ (theme === 'dark' ? DARK_THEME : LIGHT_THEME) }>
      <Routes>
        <Route
          path='/login'
          exact={ true }
          element={ <Login /> } />

        <Route
          path={ config.OKTA_CALLBACK_ROUTE }
          element={ <LoginCallback errorComponent={ Error } /> } />

        <Route element={ <OktaAuthenticatedRoute /> }>
          <Route element={ layout }>
            <Route path={ '*' } element={ <NotFound /> } />
            <Route path='' exact={ true } element={ <HomeView /> } />
            <Route path='/visualize' exact={ true } element={ <VisualizeView /> } />
            <Route path='/user/:id' element={ <UserView /> } />
            <Route path='/activity/:id' element={ <ActivityView /> } />
            <Route path='/me' exact={ true } element={ <ProfileView /> } />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}, R.pipe(
  propTypes({}),
  defaultProps({}),
  memo,
));
