// modules
import React, { memo, useCallback } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// aliased
import FlexCenter from 'components/FlexCenter';
import ValiantLogo from 'components/ValiantLogo';
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './Login.module.scss';

export default R.applyTo(() => {
  const { oktaAuth, authState } = useOktaAuth();
  const location = useLocation();

  const isAuthenticated = R.propOr(false, 'isAuthenticated', authState);
  const redirect = R.pathOr('/', ['state', 'redirect'], location);
  
  const theme = R.path(['palette', 'type'], useTheme());

  const handleLogin = useCallback(() => {
    oktaAuth.signInWithRedirect({ originalUri: redirect });
  }, [oktaAuth, redirect]);

  if (isAuthenticated) return <Navigate to={ redirect } />;

  return (
    <FlexCenter className={ cx(styles.wrapper, { [styles[theme]]: true }) }>
      <ValiantLogo className={ styles.logo } />
      <h1 style={{ padding: '20px 0' }}>Activity Research Services</h1>
      <div>
        <Button
          size={ 'large' }
          variant='outlined'
          onClick={ handleLogin }>Login</Button>
      </div>
    </FlexCenter>
  );
}, R.pipe(
  propTypes({
    title: PropTypes.string,
  }),
  defaultProps({
    title: 'Login',
  }),
  memo,
));
