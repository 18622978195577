import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// String -> Undefined
export const useGATracking = trackingId => {
  const location = useLocation();

  useEffect(() => {
    if (!window.gtag || !trackingId) return;
    window.gtag('config', trackingId, { page_path: location.pathname }); // eslint-disable-line camelcase
  }, [trackingId, location]);

};
