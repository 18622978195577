import * as R from 'ramda';

// mutating
export const propTypes = R.curry((propTypes, component) => {
  return Object.assign(component, { propTypes });
});

// mutating
export const defaultProps = R.curry((defaultProps, component) => {
  return Object.assign(component, { defaultProps });
});
