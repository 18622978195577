import { useEffect } from 'react';

// aliased
import { useLazyRelayConnection } from './useLazyRelayConnection';

// GraphQLQuery -> Object -> Object
export const useRelayConnection = (query, args) => {
  const [runQuery, res] = useLazyRelayConnection(query, args);

  // run initial query on mount
  useEffect(() => {
    runQuery();
  }, []);

  return res;
};
