// modules
import React, { memo, useState, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as R from 'ramda';
import { useTheme } from '@material-ui/core/styles';
import {
  Menu as MenuIcon,
  OpenInNew as OpenInNewIcon,
} from '@material-ui/icons';
import {
  CssBaseline,
  Container,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Switch,
  Divider,
} from '@material-ui/core';

// aliased
import UserAvatar from 'components/UserAvatar';
import Link from 'components/Link';
import ValiantLogo from 'components/ValiantLogo';
import ErrorBoundary from 'components/ErrorBoundary';
import { propTypes, defaultProps } from 'lib/react';
import config from 'conf';

// local
import Menu from './components/Menu';
import styles from './Layout.module.scss';
import './base.scss';
import SearchInput from 'components/SearchInput';

export default R.applyTo(({
  className,
  user,
  supportEmail,
  onToggleTheme,
  onLogout,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const theme = R.path(['palette', 'type'], useTheme());

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [setIsMenuOpen, isMenuOpen]);

  const handleThemeToggle = useCallback(event => {
    onToggleTheme(event.target.checked ? 'light' : 'dark');
  }, [onToggleTheme]);

  return (
    <ErrorBoundary className={ styles.layoutErrorBoundary }>
      <CssBaseline />
      <Menu
        hidden={ !isMenuOpen }
        user={ user }
        onLogout={ onLogout }
        onClose={ handleMenuToggle } />
      <div className={ cx(styles.site, theme, className) }>
        <AppBar component='header' position="static" className={ styles.appBar }>
          <Container>
            <Toolbar style={{ width: '100%', padding: 0 }}>

              <div style={{ alignItems: 'center' }}>
                <IconButton
                  style={{ marginLeft: -12 }}
                  className={ styles.menu }
                  onClick={ handleMenuToggle }>
                  <MenuIcon />
                </IconButton>
                <Link to="/" className={ styles.logo } style={{ verticalAlign: 'middle' }}>
                  <ValiantLogo />
                </Link>
              </div>

              <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ padding: '0 30px' }}>
                  <Typography variant='h3'>
                    { 'Activity Research Services' }
                  </Typography>
                </div>

                <div style={{ paddingTop: '5px', verticalAlign: 'middle', textAlign: 'center', flex: 1 }}>
                  <SearchInput style={{ width: '70%', minWidth: '300px', paddingRight: '30px' }} />
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Link to={ '/me' } style={{ textDecoration: 'none' }}>
                  <UserAvatar user={ user } />
                </Link>
              </div>

            </Toolbar>
          </Container>
        </AppBar>

        <main className={ styles.main } id="main">
          <Outlet />
        </main>

        <footer>
          <Container>
            <Grid container={ true } spacing={ 2 } className={ styles.footer }>
              <Grid item={ true } xs={ 12 } md={ 6 }>
                <Typography variant="h5">Contact</Typography>
                <Divider />
                <nav>
                  <Link
                    to={
                      // eslint-disable-next-line max-len
                      `mailto:${ supportEmail }?subject=Support Request: ARS Console v${ config.version } (${ config.env })`
                    }>
                    <OpenInNewIcon fontSize='small' style={{ marginRight: 10 }} />
                    Email Support
                  </Link>
                </nav>
              </Grid>
              <Grid item={ true } xs={ 12 } md={ 6 }>
                <Typography variant="h5">Help</Typography>
                <Divider />
                <nav>
                  <Typography>version: { config.version }</Typography>
                  <Typography>environment: { config.env }</Typography>
                  <Typography component='div' style={{ marginTop: -15 }}>
                    <Grid component="label" container={ true } alignItems="center" spacing={ 1 }>
                      <Grid item={ true }>Dark</Grid>
                      <Grid item={ true }>
                        <Switch
                          checked={ theme === 'light' }
                          onChange={ handleThemeToggle } />
                      </Grid>
                      <Grid item={ true }>Light</Grid>
                    </Grid>
                  </Typography>
                </nav>
              </Grid>
            </Grid>
          </Container>
        </footer>
      </div>
    </ErrorBoundary>
  );
}, R.pipe(
  propTypes({
    className: PropTypes.string,
    supportEmail: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  defaultProps({
    className: null,
    user: null,
  }),
  memo,
));
