// modules
import React, { memo } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import FlexCenter from 'components/FlexCenter';

export default R.applyTo(({ children, hidden, className, style }) => {
  if (hidden) return null;
  return (
    <FlexCenter style={ style } className={ className }>
      <CircularProgress color='secondary' style={{ margin: '40px 0' }} />
      <div style={{ fontSize: '26px' }}>{ children }</div>
    </FlexCenter>
  );
}, R.pipe(
  propTypes({
    className: PropTypes.string,
  }),
  defaultProps({}),
  memo,
));
