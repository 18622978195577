import * as R from 'ramda';

// produces a hex color for a given string, with variable seed
// determinstic for each (seed, string) input pair
// Integer -> String -> String HexColor
const stringColorWith = R.curry((seed, str) => {
  // Generate a hash code from the string
  let hash = seed;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // Convert the hash code to a 6-digit hexadecimal color code
  const color = `#${  ((hash & 0xffffff) | 0x1000000).toString(16).slice(1) }`;
  
  return color;
});

// produces a hex color for a given string
// determinstic
// String -> String HexColor
export const stringColor = stringColorWith(9);
