// modules
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import fetch from 'unfetch';
import * as R from 'ramda';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination as relayFieldPolicy } from '@apollo/client/utilities/policies/pagination';
// import { relayStylePagination } from '@apollo/client/utilities';
import { onError } from '@apollo/link-error';
import { useOktaAuth } from '@okta/okta-react';
import jwtDecode from 'jwt-decode';
import { isPast } from 'date-fns';

// aliased
import { keyArgs } from 'lib/graphql';
// import relayFieldPolicy from 'lib/graphql/relay-field-policy';
import { propTypes } from 'lib/react';
import config from 'conf';

const isTokenExpired = R.pipe(
  jwtDecode,
  R.path(['exp']),
  exp => new Date(exp * 1000),
  isPast,
);

const httpLink = new HttpLink({
  uri: `${ config.API_HOST }/graphql`,
  fetch,
  // seeing a lot of strange issues that seem to be related to this...
  // useGETForQueries: true,
});

const authMiddleware = authService => new ApolloLink((operation, next) => {
  const { accessToken } = operation.getContext();

  if (isTokenExpired(accessToken)) {
    if (authService) authService.clearAuthState();
  }
  if (accessToken) {
    operation.setContext({
      headers: { authorization: `Bearer ${ accessToken }` },
    });
  }

  return next(operation);
});



// @TODO implement cache once we know what the data looks like?
const cache = new InMemoryCache({
  // Include this object to define polymorphic relationships between your schema's types.
  // Doing so enables you to look up cached data by interface or by union.
  // todo: read this from the graph at build-time
  possibleTypes: {
    Node: ['Activity', 'NikeEmployee', 'User', 'App', 'Collection'],
    AbstractUser: ['User', 'NikeEmployee'],
  },
  typePolicies: {
    User: {
      fields: {
        activities: relayFieldPolicy({
          keyArgs: keyArgs([
            R.path(['input', 'sortOrder']),
            R.path(['input', 'sortField']),
            R.path(['input', 'first']),
          ]),
        }),
      },
    },
    App: {
      fields: {
        activities: relayFieldPolicy({
          keyArgs: keyArgs([
            R.path(['input', 'sortOrder']),
            R.path(['input', 'sortField']),
            R.path(['input', 'first']),
          ]),
        }),
      },
    },
    Collection: {
      fields: {
        activities: relayFieldPolicy({
          keyArgs: keyArgs([
            R.path(['input', 'sortOrder']),
            R.path(['input', 'sortField']),
            R.path(['input', 'first']),
          ]),
        }),
      },
    },
    Query: {
      fields: {
        apps: relayFieldPolicy({
          keyArgs: keyArgs([
            R.path(['input', 'first']),
          ]),
        }),
        activities: relayFieldPolicy({
          keyArgs: keyArgs([
            R.path(['input', 'sortOrder']),
            R.path(['input', 'sortField']),
            R.path(['input', 'first']),
          ]),
        }),
      },
    },
  },
});

export default R.applyTo(({ children }) => {
  const { authState, authService } = useOktaAuth();

  const withAccessToken = setContext(() => (authState.isAuthenticated
    ? R.path(['accessToken'], authState)
    : null
  ));

  const client = new ApolloClient({
    link: ApolloLink.from([
      withAccessToken,
      authMiddleware(authService),
      onError(({ graphQLErrors, networkError, operation, ...rest }) => {
        console.log('ApolloLink.onError', { graphQLErrors, networkError, operation, ...rest });
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(`[GraphQL Error]: Message: ${ message }, Location: ${ locations }, Path: ${ path }`);
          });
        }
        const status = R.path(['response', 'status'], operation.getContext());
        if (status === 401) { // if 401 from API. log the user out.
          console.error('401 - Unauthorized');
          if (authService) authService.clearAuthState();
        }
        if (networkError) console.log(`[Network Error]: ${ networkError }`);
      }),
      httpLink,
    ]),
    cache,
    connectToDevTools: true,
    typeDefs: {},
  });

  return (
    <ApolloProvider client={ client }>
      { children }
    </ApolloProvider>
  );
}, R.pipe(
  propTypes({
    children: PropTypes.node,
  }),
  memo,
));
