// modules
import * as R from 'ramda';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

// local
import BASE_THEME from './base';

const $nearBlack = '#111111';

const LIGHT_THEME = {
  palette: {
    type: 'light',
    background: {
      default: '#ffffff',
    },
    primary: {
      main: $nearBlack,
      text: $nearBlack,
    },
    secondary: {
      main: '#3498DB',
    },
  },

  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#ffffff',
      },
    },
  },
};

export default R.applyTo(LIGHT_THEME, R.pipe(
  R.mergeDeepRight(BASE_THEME),
  createTheme,
  responsiveFontSizes
));
