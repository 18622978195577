// modules
import * as R from 'ramda';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

// local
import BASE_THEME from './base';

const $nearBlack = '#111111';

const DARK_THEME = {
  palette: {
    type: 'dark',
    background: {
      default: $nearBlack,
    },
    primary: {
      main: '#fafafa',
      text: '#ffffff',
    },
    secondary: {
      main: '#3498DB',
    },
  },

  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: '0px 2px 4px -1px rgba(255, 255, 255, 0.2), 0px 4px 5px 0px rgba(255, 255, 255, 0.14), 0px 1px 10px 0px rgba(255, 255, 255, 0.12)',
      },
      colorPrimary: {
        backgroundColor: $nearBlack,
      },
    },

  },
};

export default R.applyTo(DARK_THEME, R.pipe(
  R.mergeDeepRight(BASE_THEME),
  createTheme,
  responsiveFontSizes
));
