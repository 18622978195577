import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import cx from 'classnames';
import * as R from 'ramda';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './Menu.module.scss';

export default R.applyTo(({
  onClose,
  className,
  hidden = false,
  onLogout,
}) => {
  const theme = R.path(['palette', 'type'], useTheme());

  const classNames = cx(styles.wrapper, {
    [styles.dark]: (theme === 'dark'),
    [styles.hidden]: hidden,
  }, className);

  return (
    <div
      className={ classNames }>
      <IconButton onClick={ onClose } className={ styles.close }>
        <CloseIcon />
      </IconButton>
      <nav className={ styles.mobileMenu }>
        <Link to='/' onClick={ onClose } className={ styles.link }>Activities</Link>
        <Link to='/visualize' onClick={ onClose } className={ styles.link }>Visualize</Link>
        <Link
          to='https://console.platform.test.vl.nikecloud.com/graphql?host=ars-admin'
          onClick={ onClose }
          className={ styles.link }
        >GraphQL</Link>
        <Link to='/me' onClick={ onClose } className={ styles.link }>Profile</Link>
        <Link onClick={ onLogout } className={ styles.link }>Logout</Link>
      </nav>
    </div>
  );
}, R.pipe(
  propTypes({
    className: PropTypes.string,
    onClose: PropTypes.func,
    onLogout: PropTypes.func,
  }),
  defaultProps({
    className: null,
    onClose: () => {},
    onLogout: () => {},
  }),
  memo,
));
