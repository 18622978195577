/* eslint-disable react/no-multi-comp */

// Integer -> String
// formatDuration(125000) -> "02:05"
// eslint-disable-next-line max-statements
export const formatDuration = milliseconds => {
  const isNeg = milliseconds < 0;
  if (isNeg) milliseconds = -milliseconds;
  let seconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  seconds %= 60;

  let formattedDuration = '';
  if (hours > 0) {
    formattedDuration += `${ hours.toString().padStart(2, '0') }:`;
  }
  if (hours > 0 || minutes > 0) {
    formattedDuration += `${ minutes.toString().padStart(2, '0') }:`;
  }
  formattedDuration += seconds.toString().padStart(2, '0');
  if (hours <= 0 && minutes <= 0) formattedDuration = `00:${ formattedDuration }`;

  return isNeg ? `-${ formattedDuration }` : formattedDuration;
};
