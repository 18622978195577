import React from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import { Grid, Button } from '@material-ui/core';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import styles from './DataGridPagination.module.scss';

export default applyTo(({
  hasNextPage,
  hasPreviousPage,
  disabled,
  onLoadNextPageClick,
  onLoadPreviousPageClick,
  onChangePage,
  currentPage,
  totalPages,
  style,
}) => {
  return (
    <Grid
      className={ styles.dataGridPagination }
      container={ true }
      justifyContent='flex-end'
      alignItems='flex-end'
      style={{ padding: 10, ...style }}>
      <div />
      <Button
        className={ styles.nextPrev }
        variant='outlined'
        disabled={ disabled || !hasPreviousPage }
        onClick={ onLoadPreviousPageClick }
        title={
          R.applyTo(null, R.cond([
            [_ => disabled, _ => ''],
            [_ => !hasPreviousPage, _ => 'No Previous Page'],
            [R.T, _ => 'Goto Previous Page'],
          ]))
        }
      >
        { '<' }
      </Button>
      {
        R.range(1, totalPages + 1).map(page => (
          <Button
            className={ cx(styles.page, { [styles.active]: ((page === currentPage) && totalPages > 1) }) }
            key={ page }
            disabled={ disabled || (page === currentPage) }
            title={ `Goto Page ${ page }` }
            // this is okay since it's in an array and we are only re-rendering a button
            // eslint-disable-next-line react/jsx-no-bind
            onClick={ _ => onChangePage(page) }
          >
            { page }
          </Button>
        ))
      }
      <Button
        className={ styles.nextPrev }
        variant='outlined'
        disabled={ (disabled || !hasNextPage) }
        onClick={ onLoadNextPageClick }
        title={
          R.applyTo(null, R.cond([
            [_ => disabled, _ => ''],
            [_ => !hasNextPage, _ => 'No Next Page'],
            [R.T, _ => 'Goto Next Page'],
          ]))
        }
      >
        { '>' }
      </Button>
    </Grid>
  );
}, pipe(
  propTypes({
    hasNextPage: PropTypes.bool,
    disabled: PropTypes.bool,
    onLoadNextPageClick: PropTypes.func,
    onLoadPreviousPageClick: PropTypes.func,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  defaultProps({
    disabled: false,
    currentPage: 1,
    totalPages: 1,
    hasNextPage: true,
    hasPreviousPage: false,
    onLoadNextPageClick: () => {},
    onLoadPreviousPageClick: () => {},
  }),
));
