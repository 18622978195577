/* note: this file cannot be named "config" due to an unexplainable NODE_PATH + webpack bug */

// local
import packageJson from '../package.json';

/* eslint-disable no-process-env, no-undef */
const {
  REACT_APP_GOOGLE_ANALYTICS_ID: GOOGLE_ANALYTICS_ID = '',
  REACT_APP_INFRA_NODE_ENV: env = 'production',
  CI = false,
  REACT_APP_API_HOST: API_HOST = '',
  REACT_APP_OKTA_URL: OKTA_URL = '',
  REACT_APP_OKTA_CLIENT_ID: OKTA_CLIENT_ID = 'nike.valiantlabs.ars-console',
} = process.env;
/* eslint-enable no-process-env, no-undef */

const CALLBACK_ROUTE = '/callback';

// required env vars
const envOkay = [API_HOST]
  .every(env => env);
if (!envOkay) {
  // eslint-disable-next-line no-console
  console.warn(
    'One or more env vars are missing. Please check your .env file in the project root.'
  );
}

// const HOSTS = {
//   development: 'https://console.ars.test.vl.nikecloud.com/',
//   production: 'https://console.ars.vl.nikecloud.com/',
// };

const CONFIG = {
  env,
  version: packageJson.version,
  GOOGLE_ANALYTICS_ID,
  API_HOST,
  OKTA_URL,
  OKTA_CLIENT_ID,
  OKTA_CALLBACK_ROUTE: CALLBACK_ROUTE,
  REDIRECT_URI: `${ window.location.origin }${ CALLBACK_ROUTE }`,
  SUPPORT_EMAIL: 'colby.dauphinais@nike.com',
  CI,
  // DOMAIN: prop(env, HOSTS),
};

export default CONFIG;
