// modules
import React, { useState, useCallback } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import * as R from 'ramda';
import { mapKeys } from '@vl/js-lib/browser/object';

// aliased
import MetricChart from 'components/MetricChart';
import NestedData from 'components/NestedData';
import FileDrop from 'components/FileDrop';
import Link from 'components/Link';
import { formatDuration } from 'lib/date';
import { unslugify } from 'lib/string';
import { upload as uploadFile } from 'lib/file';

// local
import styles from './VisualizeView.module.scss';
import { format } from 'date-fns/fp';

// const METRICS = ['HEART_RATE', 'DISTANCE', 'RPE', 'IMPACT'];
// const randomMetric = R.curry((date, type) => ({
//   type,
//   value: faker.datatype.number({ min: 0, max: 200 }),
//   startDate: date,
//   endDate: date,
// }));
// const [metrics, setMetrics] = useState([]);
// useEffect(() => {
//   const id = setInterval(() => {
//     const now = new Date();
//     setMetrics(metrics => [
//       ...metrics,
//        ...R.map(randomMetric(now), [sample(METRICS)]),
//     ])
//   }, 1000);
//   return _ => clearInterval(id);
// }, []);

// eslint-disable-next-line max-statements
const VisualizeView = () => {
  const [activityState, setActivity] = useState(null);
  const palette = R.path(['palette', 'type'], useTheme());

  const activity = R.prop('activity', activityState);
  const fileSize = R.prop('size', activityState);
  const filename = R.prop('filename', activityState);

  const metrics = R.applyTo(activity, R.pipe(
    R.propOr([], 'metrics'),
    R.chain(({ values, type }) => values.map(({ startDate, endDate, ...rest }) => ({
      ...rest,
      type,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    }))),
    R.sortBy(R.prop('endDate')),
  ));
  
  const handleFileDrop = useCallback(([file]) => {

    uploadFile(file)
      .then(fileData => {
        const activity = {
          activity: JSON.parse(fileData),
          size: file.size,
          filename: file.name,
        };
        setActivity(activity);
        console.log('Uploaded JSON data:', activity);
      })
      .catch(err => console.error('Error parsing JSON:', err));
  
  }, [setActivity, uploadFile]);

  const formatDate = useCallback(date => {
    if (!R.prop('startDate', activity)) return date.toISOString();
    return formatDuration(date - new Date(activity.startDate));
  }, [activity]);

  const tableData = R.applyTo(activity, R.unless(R.isNil, R.pipe(
    activity => ({
      filename,
      size: `${ (fileSize / 1000000).toFixed(2) } MB`,
      ...activity,
      duration: formatDuration(activity.duration || 0),
      activeDuration: formatDuration(activity.activeDuration || 0),
      metrics: R.reduce((summary, metric) => {
        const type = unslugify(metric.type);
        summary['(Total)'] = (summary['(Total)'] || 0);
        summary[type] = (summary[type] || 0);
        summary['(Total)']++;
        summary[type]++;
        return summary;
      }, {}, metrics),
      startDate: format('MMM dd yyyy @ HH:mm:ss xxx', new Date(activity.startDate)),
      endDate: format('MMM dd yyyy @ HH:mm:ss xxx', new Date(activity.endDate)),
      summaries: R.applyTo(activity, R.pipe(
        R.propOr([], 'summaries'),
        R.groupBy(R.prop('metric')),
        mapKeys(unslugify),
        R.map(R.pipe(
          R.map(({ summary, value }) => [unslugify(summary), value]),
          R.fromPairs,
        )),
      )),
    }),
    R.evolve({
      id: id => (
        <Link to={ `/activity/${ id }` }>
          { id }
        </Link>
      ),
      activityType: unslugify,
    }),
    ({ userId, nuId, ...rest }) => ({
      user: {
        nuId: (<Link to={ `/user/${ userId }` }>{ nuId }</Link>),
      },
      ...rest,
    }),
    R.pick([
      'id', 'user',
      'filename',
      'activityType', 'size',
      'appId', 'collectionId',
      'startDate', 'endDate', 'duration', 'activeDuration',
      'summaries',
      'metrics',
      'moments',
      'metadata',
    ]),
  )));

  return (
    <Container className={ cx(styles.visualizeView, styles[palette]) }>
      <Typography variant='h1' align={ 'center' }>
        { 'Visualize' }
      </Typography>
      <FileDrop
        className={ styles.fileDrop }
        onDrop={ handleFileDrop }
        showHint={ !activity }
        hint={ 'Drop Activity File' }
      >
        <MetricChart
          formatDate={ formatDate }
          metrics={ metrics } />
      </FileDrop>
      {
        activity && (
          <NestedData
            data={ tableData }
            style={{ fontSize: '20px' }} />
        )
      }
    </Container>
  );
};

export default VisualizeView;
