// modules
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as R from 'ramda';

// aliased
import FlexCenter from 'components/FlexCenter';

// local
import styles from './FileDrop.module.scss';


const FileDrop = ({
  className,
  onDrop,
  children,
  showHint,
  fileTypes: allowedFileTypes,
  hint,
  ...props
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleDrop = useCallback(event => {
    event.preventDefault();
    const files = [...event.dataTransfer.files];
    
    setIsDragging(false);
    if (files.length) onDrop(files, event);
  }, [onDrop, setIsDragging]);

  const handleDragOver = useCallback(event => {
    event.preventDefault();
    const valid = !R.any(type => type !== 'Files', event.dataTransfer.types);
    
    setIsValid(valid);
    setIsDragging(true);
  }, [setIsDragging, setIsValid]);

  const handleDragLeave = useCallback(_ => {
    setIsDragging(false);
    setIsValid(true);
  }, [setIsDragging]);

  const classNames = cx(styles.fileDrop, { [styles.isDragging]: isDragging }, className);
  
  return (
    <div
      { ...props }
      className={ classNames }
      onDrop={ handleDrop }
      onDragOver={ handleDragOver }
      onDragLeave={ handleDragLeave }
    >
      <FlexCenter
        className={ cx(styles.overlay, { [styles.isInvalid]: !isValid }) }
        style={{
          opacity: ((isDragging || showHint) ? 1 : 0),
        }}
      >
        { isValid ? hint : 'Invalid File' }
      </FlexCenter>
      { children }
    </div>
  );
};

FileDrop.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  hint: PropTypes.string,
  onDrop: PropTypes.func,
  showHint: PropTypes.bool,
  style: PropTypes.shape({}),
};

FileDrop.defaultProps = {
  hint: 'Drop File to Upload',
};

export default FileDrop;
