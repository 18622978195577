import * as R from 'ramda';
import { useOktaAuth } from '@okta/okta-react';

// _ -> OktaUser
export const useOktaUser = () => {
  const { authState } = useOktaAuth();

  if (!R.propOr(false, 'isAuthenticated', authState)) return null;

  const email = R.path(['idToken', 'claims', 'email'], authState);
  const name = R.path(['idToken', 'claims', 'name'], authState);
  const [firstName, lastName] = R.split(' ', name);

  return { email, firstName, lastName };
};
