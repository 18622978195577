/* eslint-disable max-statements */
import { useNavigate } from 'react-router-dom';

import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { TextField } from '@material-ui/core';
import { toBase64 } from '@vl/js-lib/browser/string';
import { defaultProps, propTypes } from 'lib/react';
import { fromGlobalId } from 'lib/graphql';

const nuidRegex = /^\s*nuid\s*:\s*/i;

export default R.applyTo(({ style }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  //pr: do I need to call useCallback here since the dom isn't calling it?
  //    or is it just best practice?
  const callSearch = useCallback(
    () => {
      const normalizedQuery = R.applyTo((searchQuery || ''), R.pipe(
        R.trim,
      ));
      
      if (R.test(nuidRegex, normalizedQuery)) {
        const id = R.applyTo(normalizedQuery, R.pipe(
          // accounts for whitespaces
          R.replace(nuidRegex, ''),
          R.concat('User:'),
          R.curry(toBase64),
        ));
    
        navigate(`/user/${ id }`);
        setSearchQuery('');
        setError(null);
      } else {
        try {
          const { type } = fromGlobalId(normalizedQuery);
          if (['User', 'Activity'].includes(type)) {
            navigate(`/${ type }/${ normalizedQuery }`); // since this is the full GID
            setSearchQuery('');
            setError(null);
          } else {
            throw Error('Unknown ID');
          }
        } catch (err) {
          setError('Unknown ID');
        }
      }
    },
    [navigate, searchQuery]);

  const change = R.pipe(
    R.pathOr('', ['target', 'value']),
    R.tap(R.when(R.isEmpty, _ => setError(null))),
    setSearchQuery,
  );
  const keyDown = R.pipe(R.propOr(-1, 'keyCode'), R.when(R.equals(13), callSearch));
  
  return (
    <TextField
      style={ style }
      onChange={ change }
      onKeyDown={ keyDown }
      value={ searchQuery }
      label="Search"
      variant="outlined"
      placeholder="Activity or User ID..."
      size="small"
      error={ !!error }
      helperText={ error }
    />
  );
}, R.pipe(
  propTypes({
  }),
  defaultProps({
  }),
));
