/* eslint-disable react/no-multi-comp */
import React, { useCallback } from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import { DataGrid } from '@material-ui/data-grid';
import { LinearProgress, Container } from '@material-ui/core';
import { applyTo, pipe } from 'ramda';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import Pagination from 'components/DataGridPagination';

// local
import COLUMNS from './columns';
import styles from './ActivityList.module.scss';

export default applyTo(({
  // error,
  activities,
  className,
  columns: columnVisibility,
  loading,
  nextPage,
  noRowsOverlay,
  onSortModelChange,
  page,
  prevPage,
  setPage,
  sort: sorts,
  totalPages,
}) => {
  // fixme: hack to force-render to fix row missing issue
  // const [key, setKey] = useState(Math.random());
  // useEffect(() => setKey(Math.random()), [activities]);
  const palette = R.path(['palette', 'type'], useTheme());

  const classNames = cx(className, styles.activityList, {
    [styles[palette]]: true,
  });

  const columns = COLUMNS.filter(({ field }) => (columnVisibility[field] !== false));
  
  // since setting the initial sorts trigger the onSortModelChange, we need to check manually
  const handleSortChange = useCallback(incoming => {
    if (!R.equals(sorts, incoming)) {
      onSortModelChange(incoming);
    }
  }, [sorts]);

  return (
    <Container>
      <DataGrid
        className={ classNames }
        // key={ key }
        rows={ activities }
        sortingOrder={ ['desc', 'asc'] }
        columns={ columns }
        sortModel={ sorts }
        checkboxSelection={ false }
        disableSelectionOnClick={ true }
        components={{
          NoRowsOverlay: () => (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '20px',
                marginTop: '56px',
              }}
            >
              { noRowsOverlay }
            </div>
          ),
          // fixme: consider using DataGrid's built-in pagination instead
          Pagination: () => (
            !!activities.length && (
              <Pagination
                style={{ maxWidth: '500px' }}
                onLoadNextPageClick={ nextPage }
                onLoadPreviousPageClick={ prevPage }
                hasNextPage={ !!nextPage }
                hasPreviousPage={ !!prevPage }
                currentPage={ page }
                totalPages={ totalPages }
                onChangePage={ setPage }
                disabled={ loading }
              />
            )
          ),
          // eslint-disable-next-line react/no-multi-comp
          LoadingOverlay: () => (<LinearProgress color={ 'secondary' } />),
        }}
        onSortModelChange={ handleSortChange }
        sortingMode={ 'server' }
        loading={ loading }
        autoHeight={ true }
      />
    </Container>
  );
}, pipe(
  propTypes({
    activities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    columns: PropTypes.shape({}),
  }),
  defaultProps({
    activities: [],
    noRowsOverlay: 'No Rows',
    columns: {},
    onSortModelChange: _ => {},
  }),
));
