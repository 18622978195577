import { isFunction, isObject } from '@vl/js-lib/browser/is';
import { fromBase64, toBase64 } from '@vl/js-lib/browser/string';
import * as R from 'ramda';

// functional version of apollo client's keyArgs array syntax
// composes multiple query args into a single cache key
// [(a -> String)] -> a -> String
const keyArgs = R.curry((keyGetters, args) => {
  return R.applyTo(keyGetters, R.pipe(
    R.map(R.applyTo(args)),
    R.reject(R.isNil),
    R.join(':'),
  ));
});

const GID_SEP = ':';

function serializeIdObj(obj) {
  if (!isObject(obj)) return obj;
  if (isFunction(obj.valueOf)) {
    const valueOfResult = obj.valueOf();
    if (!isObject(valueOfResult)) return valueOfResult;
  }
  if (isFunction(obj.toJSON)) return obj.toJSON();
  return obj;
}

// serialize a type and id to a global id
// String -> String -> String
const toGlobalId = R.curry((type, id) => {
  if (!type) throw Error(`Invalid GID type "${ type }"`);
  if (R.isNil(id) || id === '' || Number.isNaN(id)) {
    throw Error(`Invalid GID ID "${ id }"`);
  }
  return toBase64([type, serializeIdObj(id)].join(GID_SEP));
});

// parse a global id
// String -> Node
const fromGlobalId = gid => {
  const decoded = fromBase64(`${ gid }`);
  const [type, id] = decoded.split(GID_SEP);
  // note: this is more strict than the graphql-relay version
  if (!type || R.isNil(id) || id === '') throw Error(`Invalid GID "${ gid }"`);
  return { type, id };
};


export {
  keyArgs,
  toGlobalId,
  fromGlobalId,
};
