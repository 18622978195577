/* eslint-disable max-statements */
import React, { useCallback } from 'react';
import * as R from 'ramda';
import { applyTo, pipe } from 'ramda';
import cx from 'classnames';
import { gql, useQuery } from '@apollo/client';
import { Container, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import { useRelayConnection } from 'lib/hooks/useRelayConnection';
import { connectionNodes } from 'lib/graphql/relay';

// local
import styles from './AppCollectionSelector.module.scss';

const ALL_APPS = gql`
  query AllApps {
    apps {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

const APP_COLLECTIONS = gql`
  query AppCollections($id: ID!) {
    node(id: $id) {
      ...on App {
        id
        name
        collections {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default applyTo(({
  onAppSelected,
  onCollectionSelected,
  appId,
  collectionId,
  style,
}) => {
  const theme = R.path(['palette', 'type'], useTheme());

  const { data: apps, loading: loadingApps } = useRelayConnection(ALL_APPS, {
    getConnection: R.path(['apps']),
    returnFormat: 'nodes',
  });

  const { data, loading: loadingCollections } = useQuery(APP_COLLECTIONS, {
    skip: !appId,
    variables: { id: appId },
  });

  const collections = R.applyTo(data, R.pipe(
    R.path(['node', 'collections']),
    connectionNodes,
  ));

  const handleAppChange = useCallback(R.pipe(
    R.path(['target', 'value']),
    id => R.find(R.propEq('id', id), apps),
    R.tap(onAppSelected),
  ), [onAppSelected, apps]);

  const handleCollectionChange = useCallback(R.pipe(
    R.path(['target', 'value']),
    id => R.find(R.pathEq(['id'], id), collections),
    R.tap(onCollectionSelected),
  ), [apps, onCollectionSelected]);
  
  return (
    <Container className={ cx(styles.container, styles[theme]) } style={ style }>
      <span style={{ fontSize: '24px', verticalAlign: 'bottom', marginRight: '20px' }}>
        { 'Latest Activities From ' }
      </span>
      <FormControl>
        <InputLabel>
          {
            loadingApps
              ? ('Loading Apps...')
              : (
                appId
                  ? 'App'
                  : 'Select An App'
              )
          }
        </InputLabel>
        <Select
          defaultValue={ '' }
          disabled={ loadingApps }
          label="Application"
          onChange={ handleAppChange }
          value={ (appId || '') }
          style={{
            minWidth: '200px',
            marginRight: '20px',
          }}
        >
          {
            R.map(app => (
              <MenuItem value={ app.id } key={ app.id }>{ app.name }</MenuItem>
            ), apps)
          }
        </Select>
      </FormControl>
      {
        appId && (
          <FormControl>
            <InputLabel>
              {
                loadingCollections
                  ? 'Loading Collections...'
                  : (collectionId ? 'Collection' : 'Select a Collection')
              }
            </InputLabel>
            <Select
              defaultValue={ '' }
              label="Collection"
              disabled={ !appId }
              value={ (collectionId || '') }
              onChange={ handleCollectionChange }
              style={{ minWidth: '200px' }}
            >
              <MenuItem value={ '' } key={ '__EMPTY__' }>{ '(All)' }</MenuItem>
              {
                R.map(collection => (
                  <MenuItem value={ collection.id } key={ collection.id }>{ collection.name }</MenuItem>
                ), collections)
              }
            </Select>
          </FormControl>
        )
      }
    </Container>
  );
}, pipe(
  propTypes({

  }),
  defaultProps({
    onAppSelected: _ => {},
    onCollectionSelected: _ => {},
  }),
));
