// modules
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import { ToastProvider } from 'react-toast-notifications';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// aliased
import App from 'views/App';
import OktaProvider from 'components/OktaProvider';
import ApolloProvider from 'components/ApolloProvider';

// local
import * as serviceWorker from './serviceWorker';
import './index.scss';

const Root = (
  <OktaProvider>
    <ApolloProvider>
      <StylesProvider injectFirst={ true }>
        <ToastProvider>
          <MuiPickersUtilsProvider utils={ DateFnsUtils }>
            <App />
          </MuiPickersUtilsProvider>
        </ToastProvider>
      </StylesProvider>
    </ApolloProvider>
  </OktaProvider>
);

const router = createBrowserRouter([
  { path: '*', element: Root },
]);


createRoot(document.getElementById('root'))
  .render(<RouterProvider router={ router } />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
