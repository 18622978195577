// modules
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import config from 'conf';

const oktaAuth = new OktaAuth({
  issuer: config.OKTA_URL,
  clientId: config.OKTA_CLIENT_ID,
  redirectUri: config.REDIRECT_URI,
  pkce: true,
});


export default R.applyTo(({ children }) => {
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(async (_, originalUri) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true });
  }, []);

  return (
    <Security oktaAuth={ oktaAuth } restoreOriginalUri={ restoreOriginalUri }>
      { children }
    </Security>
  );
},
R.pipe(
  propTypes({
    children: PropTypes.node,
  }),
  defaultProps({
    children: null,
  }),
  memo
));
