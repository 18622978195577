// modules
import React, { memo } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

// aliased
import { propTypes, defaultProps } from 'lib/react';

export default R.applyTo(({
  title,
}) => (
  <title>{ `${ title } | ARS` }</title>
), R.pipe(
  propTypes({
    title: PropTypes.string,
  }),
  defaultProps({
    title: '',
  }),
  memo,
));
