import React from 'react';
import { format, parseISO } from 'date-fns/fp';
import * as R from 'ramda';

// aliased
import Link from 'components/Link';
import { formatDuration } from 'lib/date';
import { unslugify } from 'lib/string';

export default [
  {
    field: 'id',
    headerName: 'ID',
    renderCell: ({ id }) => (
      <Link to={ `/activity/${ id }` }>
        ...{ R.takeLast(7, id) }
      </Link>
    ),
    hideable: false,
    sortable: false,
    filterable: false,
    width: 100,
  },
  {
    field: 'user',
    headerName: 'User (nuId)',
    renderCell: props => (
      <Link to={ `/user/${ R.path(['row', 'user', 'id'], props) }` }>
        { R.path(['row', 'user', 'nuId'], props) }
      </Link>
    ),
    sortable: false,
    filterable: false,
    width: 300,
  },
  {
    field: 'app',
    headerName: 'App',
    width: 150,
    valueGetter: R.path(['row', 'app', 'name']),
    sortable: false,
    filterable: false,
  },
  {
    field: 'collection',
    headerName: 'Collection',
    width: 150,
    valueGetter: R.path(['row', 'collection', 'name']),
    sortable: false,
    filterable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 150,
    valueGetter: R.pipe(R.path(['row', 'type']), unslugify),
    sortable: false,
    filterable: false,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    valueGetter: R.pipe(R.path(['row', 'duration']), formatDuration),
    sortable: false,
    width: 150,
    filterable: false,
  },
  {
    field: 'startDate',
    headerName: 'Start Date (Local)',
    valueGetter: R.pipe(R.path(['row', 'startDate']), parseISO, format('MMMM d, y p')),
    width: 250,
    filterable: false,
    sortable: false,
  },
];
