// modules
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as R from 'ramda';
import { useTheme } from '@material-ui/core/styles';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import logoWhite from 'assets/logoWhite.png';
import logoBlack from 'assets/logoBlack.png';

const LOGOS = {
  light: logoBlack,
  dark: logoWhite,
};

// local
import styles from './ValiantLogo.module.scss';

export default R.applyTo(({ className }) => {
  const theme = R.path(['palette', 'type'], useTheme());

  return (
    <img
      src={ LOGOS[theme] }
      alt='Valiant Labs'
      className={ cx(className, styles.logo) } />
  );
}, R.pipe(
  propTypes({
    className: PropTypes.string,
  }),
  defaultProps({
    className: null,
  }),
  memo,
));
