// modules
import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns/fp';
import cx from 'classnames';
import * as R from 'ramda';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';

// aliased
import Progress from 'components/Progress';
import NestedData from 'components/NestedData';
import Title from 'components/Title';
import { formatDuration } from 'lib/date';
import { unslugify } from 'lib/string';

// local
import styles from './ActivityView.module.scss';
import { useQuery } from '@apollo/client';
import Link from 'components/Link';


const GET_ACTIVITY = gql`
query GetActivity($id: ID!) {
  node(id: $id) {
    ...on Activity {
      id
      type
      startDate
      duration
      activeDuration
      app { name }
      collection { name }
      user { id nuId }
    }
  }
}
`;

const formatDate = R.pipe(parseISO, format('MMMM d, y p'));

const formatActivity = R.unless(R.isNil, R.pipe(
  R.evolve({
    duration: formatDuration,
    activeDuration: formatDuration,
    startDate: formatDate,
    endDate: formatDate,
    type: unslugify,
    app: R.prop('name'),
    collection: R.prop('name'),
    user: R.unless(R.isNil, R.pipe(
      user => ({
        ...user,
        nuId: (
          <Link to={ `/user/${ user.id }` }>{ user.nuId }</Link>
        ),
      }),
      R.omit(['__typename', 'id']),
    )),
  }),
  R.omit(['__typename']),
));

const ActivityView = () => {
  const { id: activityId } = useParams();
  const theme = R.path(['palette', 'type'], useTheme());

  const {
    data,
    loading,
  } = useQuery(GET_ACTIVITY, {
    variables: { id: activityId },
  });

  const activity = R.applyTo(data, R.prop('node'));

  return (
    <Container className={ cx(styles.activityView, styles[theme]) }>
      <Title title={ 'Activity' } />
      <Typography variant="h2" align={ 'center' } style={{ lineHeight: 'initial', margin: '40px 0 20px' }}>
        Activity
      </Typography>
      {
        loading
          ? (<Progress />)
          : (<NestedData data={ formatActivity(activity) } />)
      }
    </Container>
  );
};

export default ActivityView;
