// modules
import React from 'react';
import { Container } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';

// aliased
import { useOktaUser } from 'lib/hooks/useOktaUser';

// local
import styles from './ProfileView.module.scss';
import Code from 'components/Code';
import { toHumanJSON } from '@vl/js-lib/browser/object';

const WHOAMI = gql`{
  whoami {
    id
  }
}`;

const ProfileView = () => {
  const user = useOktaUser();
  const palette = R.path(['palette', 'type'], useTheme());

  const { data, loading, errors } = useQuery(WHOAMI, {
    variables: {},
  });
  
  const whoami = R.path(['whoami'], data);

  return (
    <Container className={ cx(styles.profileView, styles[palette]) }>
      <h1>Logged in as..</h1>
      <h3>{ `${ user.firstName } ${ user.lastName }` }</h3>
      <h3>{ user.email }</h3>
      <Code inline={ false }>
        { loading ? 'Loading...' : toHumanJSON(errors || whoami) }
      </Code>

    </Container>
  );
};

export default ProfileView;
