import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Avatar } from '@material-ui/core';
import cx from 'classnames';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './UserAvatar.module.scss';

export default R.applyTo(({ user, size }) => {
  const [isAvatarLoaded, setAvatarLoaded] = useState(false);
  if (!user) return null;
  const { firstName, lastName } = user;
  const name = [firstName, lastName].join(' ');

  return (
    <div className={ styles.container }>
      <Avatar
        className={ cx(styles.avatar, {
          [styles.fadeIn]: isAvatarLoaded,
        }) }
        title={ `Logged in as ${ name }` }
        src={ null }
        size={ +size }
        imgProps={{
          onLoad: () => setAvatarLoaded(true),
        }}
      >
        <p>{ R.head(firstName || '') } { R.head(lastName || '') }</p>
      </Avatar>
      <Avatar
        className={ cx(styles.loader, {
          [styles.fadeOut]: isAvatarLoaded,
        }) }
        title={ `Logged in as ${ name }` }
        size={ +size }
      >
        <></>
      </Avatar>
    </div>
  );
}, R.pipe(
  propTypes({
    size: PropTypes.number,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  defaultProps({
    size: 50,
  }),
  memo,
));
