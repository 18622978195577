// modules
import React, { memo, useCallback } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { useNavigate } from 'react-router';

// aliased
import { propTypes, defaultProps } from 'lib/react';
import Code from 'components/Code';
import config from 'conf';

// local
import styles from './Error.module.scss';

export default R.applyTo(({ error, debug }) => {
  const {
    stack,
    message = 'Oops, something went wrong',
    // code = 'ERROR',
    name = 'Error',
  } = (error || {});

  const navigate = useNavigate();
  const handleRefresh = useCallback(() => navigate(0), [history]);
  const handleBack = useCallback(() => navigate(-1), [history]);

  return (
    <div className={ styles.wrapper }>
      <h2 className={ styles.header }>{ name }</h2>
      <Typography className={ styles.message }>{ message }</Typography>
      <div className={ styles.buttons }>
        <Button variant="outlined" onClick={ handleRefresh }>Refresh Page</Button>
        <Button variant="outlined" onClick={ handleBack }>{ 'Back' }</Button>
      </div>
      <Code
        className={ styles.stack }
        inline={ false }
        hidden={ (!debug || !stack) }
      >
        { stack }
      </Code>
    </div>
  );
}, R.pipe(
  propTypes({
    debug: PropTypes.bool,
    error: PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string,
      name: PropTypes.string,
      stack: PropTypes.string,
    }),
  }),
  defaultProps({
    debug: (config.env === 'development'),
    error: {},
  }),
  memo,
));
